import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import { setConfig } from 'react-hot-loader'
import Logo from './logo'
import styled from '@emotion/styled'

import { getProjectConfigValue } from '../determine-project'
import KikudooButton from './kikudooButton'

setConfig({ pureSFC: true })

const List = styled.ul`
  position: ${(props) => (props.open ? 'static' : 'absolute')};
  opacity: ${(props) => (props.open ? '1' : '0')};
  transition: ${(props) => (props.open ? 'opacity .3s linear' : '0')};
`
function Menu() {
  const [menuOpen, setMenuOpen] = useState(false)

  if (typeof document !== 'undefined') {
    document.body.classList.toggle('menu-open', menuOpen)
  }

  const data = useStaticQuery(graphql`
    query {
      allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "primary" } }) {
        edges {
          node {
            name
            count
            wordpress_id
            items {
              title
              url
              wordpress_children {
                title
                url
                wordpress_id
              }
            }
          }
        }
      }
    }
  `)

  const menuItems = data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
    (node) => {
      const path = node.url.replace(getProjectConfigValue('wordpPressUrl'), '')
      const hasChildren = node.wordpress_children
      return (
        <li
          key={node.title}
          className={`menu-item menu-item-type-custom menu-item-object-custom${
            hasChildren ? ' menu-item-has-children' : ''
          }`}
        >
          <Link
            to={path}
            getProps={(props) => {
              const { isPartiallyCurrent, isCurrent } = props
              return (isPartiallyCurrent && path !== '/') ||
                (path === '/' && isCurrent)
                ? { className: 'current' }
                : null
            }}
            dangerouslySetInnerHTML={{
              __html: path === '/' ? 'Startseite' : node.title,
            }}
          />
          {hasChildren && (
            <ul className="sub-menu">
              {node.wordpress_children.map((child) => {
                const path = child.url
                return (
                  <li key={child.wordpress_id}>
                    {' '}
                    <Link
                      to={path}
                      activeClassName="current"
                      dangerouslySetInnerHTML={{ __html: child.title }}
                    />
                  </li>
                )
              })}
            </ul>
          )}
        </li>
      )
    }
  )
  return (
    <>
      <header className="header section">
        <div className="section-inner">
          <div className="md:flex justify-between items-start content-center text-base">
            <h2 className="blog-title">
              <Link to="/">
                <Logo />
              </Link>
            </h2>

            <KikudooButton />

            <button
              type="button"
              className={`nav-toggle hidden ${menuOpen ? 'active' : ''}`}
              title="Click to view the navigation"
              href="#"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <div className="bars">
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
              </div>
            </button>
          </div>
          {menuItems && <ul className="main-menu">{menuItems}</ul>}
          <div className="clear" />
        </div>
      </header>
      {/* className={`mobile-menu ${menuOpen ? '' : 'hidden'}`} */}
      {menuItems && (
        <List open={menuOpen} className={`mobile-menu`}>
          {menuItems}
        </List>
      )}
    </>
  )
}

export default Menu
