// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-formular-versendet-js": () => import("./../src/pages/formular-versendet.js" /* webpackChunkName: "component---src-pages-formular-versendet-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontaktformular-js": () => import("./../src/pages/kontaktformular.js" /* webpackChunkName: "component---src-pages-kontaktformular-js" */),
  "component---src-pages-kunden-js": () => import("./../src/pages/kunden.js" /* webpackChunkName: "component---src-pages-kunden-js" */),
  "component---src-pages-workshops-js": () => import("./../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

