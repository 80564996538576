import React, { Component } from 'react'
import { graphql } from 'gatsby'
// import PostIcons from '../components/PostIcons'
import Layout from '../layouts'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import { projectIsTrageberatung } from '../determine-project'

class PageTemplate extends Component {
  componentDidMount() {
    const script = document.createElement('script')
    console.log('script: ', script)
    script.async = true
    script.src = 'https://kikudoo.com/embed/kikudoo-host.js'
    this.iframe.appendChild(script)
  }
  render() {
    let title = 'Stoffwindelberatung in Bielefeld – Julia Rose'
    if (projectIsTrageberatung) {
      title = 'Trageberatung in Bielefeld – Julia Rose'
    }

    const page = this.props.data.wordpressPage
    const siteMetaData = this.props.data.allWordpressSiteMetadata.edges[0].node

    return (
      <Layout>
        <Helmet title={title} />
        {siteMetaData.description && (
          <Helmet>
            <meta name="description" content={siteMetaData.description} />
          </Helmet>
        )}
        <div className="post single page type-page status-publish hentry">
          {page.featured_media && page.featured_media.localFile && (
            <div className="featured-media">
              <Img
                fluid={page.featured_media.localFile.childImageSharp.fluid}
              />
            </div>
          )}
          <div className="post-header">
            <h1
              dangerouslySetInnerHTML={{ __html: page.title }}
              className="post-title"
            />
          </div>
          {/* <PostIcons node={currentPage} css={{ marginBottom: rhythm(1 / 2) }} /> */}
          <article
            dangerouslySetInnerHTML={{ __html: page.content }}
            className="post-content"
          />
          <div class="post-content">
            <h2 style={{ marginTop: '50px' }}>Termine</h2>
            <iframe
              ref={(el) => (this.iframe = el)}
              title="Kikudoo"
              id="kikudoo-iframe"
              style={{
                width: '100%',
                minHeight: '240px',
                border: 0,
                margin: '2rem auto',
                background: 'transparent',
              }}
              src="https://kikudoo.com/public/de/providers/Yvm4xL/courses"
            ></iframe>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "workshops" }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressSiteMetadata {
      edges {
        node {
          id
          description
          url
          name
        }
      }
    }
  }
`
