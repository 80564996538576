import React from 'react'
import Layout from '../layouts'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { useIdentityContext } from 'react-netlify-identity'

const App = () => {
  const formRef = React.useRef()
  const [msg, setMsg] = React.useState('')
  const { loginUser, signupUser, load, isLoggedIn } = useIdentityContext()

  const signup = () => {
    // const email = formRef.current.email.value
    const email = 'kunden@julia-wickelt.de'
    const password = formRef.current.password.value
    signupUser(email, password)
      .then((user) => {
        console.log('Success! Signed up', user)
      })
      .catch((err) => console.error(err) || setMsg('Error: ' + err.message))
  }

  const meta = [{ name: 'robots', content: 'noindex' }]

  return (
    <Layout>
      <Helmet meta={meta} />
      {isLoggedIn ? (
        <StaticQuery
          query={graphql`
            query {
              wordpressPage(slug: { eq: "kunden" }) {
                title
                content
                date(formatString: "MMMM DD, YYYY")
                featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          `}
          render={(data) => {
            const page = data.wordpressPage
            return (
              <div className="post single page type-page status-publish hentry">
                {page.featured_media && page.featured_media.localFile && (
                  <div className="featured-media">
                    <Img
                      fluid={
                        page.featured_media.localFile.childImageSharp.fluid
                      }
                    />
                  </div>
                )}
                <div className="post-header">
                  <h1
                    dangerouslySetInnerHTML={{ __html: page.title }}
                    className="post-title"
                  />
                </div>
                {/* <PostIcons node={currentPage} css={{ marginBottom: rhythm(1 / 2) }} /> */}
                <article
                  dangerouslySetInnerHTML={{ __html: page.content }}
                  className="post-content"
                />
              </div>
            )
          }}
        />
      ) : (
        <>
          <div className="post-header">
            <h1 className="post-title">Geschützer Bereich</h1>
          </div>
          <div className="post-content">
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault()
                // const email = e.target.email.value
                const email = 'kunden@julia-wickelt.de'
                const password = e.target.password.value
                loginUser(email, password, true)
                  .then((user) => {
                    console.log('Success! Logged in', user)
                  })
                  .catch(
                    (err) =>
                      console.error(err) ||
                      setMsg('Fehler: "' + err.message + '"')
                  )
              }}
            >
              {/* <div>
            <label>
              Email:
              <input type="email" name="email" />
            </label>
          </div> */}
              <p>
                <label>
                  Bitte Passwort eingeben:&nbsp;
                  <input type="password" name="password" />
                </label>
              </p>
              <p>
                <input type="submit" value="Einloggen" />
                {msg && <pre>{msg}</pre>}
              </p>
            </form>
          </div>
        </>
      )}
    </Layout>
  )
}
function PublicRoute(props) {
  return <div>{props.children}</div>
}

export default App
