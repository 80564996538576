import React from 'react'
import { IdentityContextProvider } from 'react-netlify-identity'

const isProdEnvironment = window.location.hostname === 'www.julia-wickelt.de'
// console.log('isProdEnvironment: ', isProdEnvironment)

const url = isProdEnvironment
  ? 'https://www.julia-wickelt.de/'
  : 'https://beta.julia-wickelt.de/' // supply the url of your Netlify site instance. VERY IMPORTANT. no point putting in env var since this is public anyway

export const wrapRootElement = ({ element }) => {
  return <IdentityContextProvider url={url}>{element}</IdentityContextProvider>
}
