import { StaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import { setConfig } from 'react-hot-loader'

setConfig({ pureSFC: true })

function Footer() {
  return (
    <>
      <StaticQuery
        query={pagesQuery}
        render={data => {
          const menuItems =
            data.allWordpressWpApiMenusMenusItems &&
            data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
              node => {
                const path = node.url
                return (
                  <span key={path}>
                    <Link
                      to={path}
                      activeClassName="current"
                      dangerouslySetInnerHTML={{ __html: node.title }}
                    />{' '}
                    <span>&nbsp;</span>
                  </span>
                )
              }
            )
          return menuItems
        }}
      />
    </>
  )
}

export default Footer

const pagesQuery = graphql`
  query {
    allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "footer" } }) {
      edges {
        node {
          name
          count
          items {
            order
            title
            url
          }
        }
      }
    }
  }
`
