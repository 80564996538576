import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import Parser from 'html-react-parser'

import Layout from '../layouts'

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wordpressPage
    const siteMetaData = this.props.data.allWordpressSiteMetadata.edges[0].node

    const Content = Parser(currentPage.content, {
      replace: (domNode) => {
        // if (domNode.name === 'img') {
        //   // TODO: also check src attribute for 'wp-content/uploads'
        //   let image = media.filter(m => {
        //     return m.node.source_url === domNode.attribs.src
        //   })
        //   if (image && image[0]) {
        //     image = image[0].node.localFile
        //     return <Img fluid={image.childImageSharp.fluid} />
        //   }
        // }
      },
    })

    return (
      <Layout>
        <Helmet
          title={`${currentPage.title ? `${currentPage.title} – ` : ''}${
            siteMetaData.name
          }`}
          bodyAttributes={{
            class: 'page-template-default page ',
          }}
        />
        <div className="post single page type-page status-publish hentry">
          {currentPage.featured_media && (
            <div className="featured-media">
              <Img
                fluid={
                  currentPage.featured_media.localFile.childImageSharp.fluid
                }
              />
            </div>
          )}
          {currentPage.acf && currentPage.acf.banner && (
            <p className="banner">{currentPage.acf.banner}</p>
          )}
          <div className="post-header">
            <h1
              dangerouslySetInnerHTML={{ __html: currentPage.title }}
              className="post-title"
            />
          </div>
          <article className="post-content">{Content}</article>
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        banner
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressSiteMetadata {
      edges {
        node {
          id
          description
          url
          name
        }
      }
    }
  }
`
